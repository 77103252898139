import {GlobalLoader} from "../../../../Scripts/EduAdm/Shared/GlobalLoader"
import {NotificationModule} from "ditmer-embla";
import {initSortable} from "../../../../Scripts/EduAdm/Shared/InitSortable";

//For at intellij selv foreslår stien til GlobalLoader
export class GlobalLoaders {
    static showLoader = (msg?: any) => GlobalLoader.showLoader(msg);
    static hideLoader = (callback?: any) => GlobalLoader.hideLoader(callback);
}
export const initSortablets = (containerId, dragelementclass, callbackOnDragstopped) => { initSortable(containerId, dragelementclass, callbackOnDragstopped) };

export const initModalBtn = function(selector: string, callback?: any, precall?: any) {
    $(selector).off().on("click", async function(e) {
        e.preventDefault();

        if (precall != null)
            await precall();
        
        const href = $(this).attr("href");
        
        if (href !== undefined && href !== "#" && href !== ""){
            GlobalLoaders.showLoader("Vent venligst...")
            $.get(href, (html) => {
                GlobalLoaders.hideLoader();
                
                $("#custom-modal-container").html(html);
                $("#custom-modal").modal('show');
                $("#custom-modal-form").on("submit", function(e) {
                    e.preventDefault();
                    const url = $("#custom-modal-form").attr("action");
                    const refresh = $('#custom-modal-form').data('refresh');

                    $.ajax({
                        url: url,
                        method: "POST",
                        data: new FormData(document.querySelector('#custom-modal-form')),
                        contentType: false,
                        processData: false,
                        cache: false,
                        success: (e) => {
                            $("#custom-modal").modal('hide');
                            
                            if (refresh !== undefined && (refresh === true || refresh.toString().toLowerCase() === "true")) {
                                setTimeout(() => {
                                    location.reload();
                                }, 200)
                            }
                            else {
                                NotificationModule.showSuccessSmall("Handlingen blev udført");
                            }
                        }
                    })
                })
    
                if (callback != null)
                    callback();
            })
        }
    })
}

export const initWizard = () => {
    
    $(".wizard-tab").each((i,e) => {
        if (!$(e).hasClass("active"))
            $(e).hide();
    })
    
    $(".btn-wizard").on("click", function() {
        $(".wizard-tab").hide();
        $(".wizard-tab").removeClass("active");
        
        const target = $(this).data("target");
        const targetElement = $(target);
        targetElement.addClass("active");
        targetElement.show();
    })
}
