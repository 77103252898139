import Sortable from "sortablejs";

export const initSortable = (containerId, dragelementclass, callbackOnDragstopped) => {
    let sortableContainer = document.getElementById(containerId);
    let sortable = new Sortable(sortableContainer, {
        handle: "." + dragelementclass, // handle's class
        animation: 250,
        onEnd: () => {
            callbackOnDragstopped()
        }
    });

    return sortable;
};